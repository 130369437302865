

md-toast[md-theme=warn] .md-toast-content {
  background-color: #F44336; }

[ui-view].ng-enter,
[ui-view].ng-leave {
  transition: opacity ease-in-out 200ms; }

[ui-view].ng-enter,
[ui-view].ng-leave.ng-leave-active {
  opacity: 0; }

[ui-view].ng-enter.ng-enter-active {
  opacity: 1; }

.Page.ng-leave.ng-leave-active {
  display: none; }

.u-center {
  text-align: center; }





.ac_custom {
  padding: 0;
  margin: 0; }

.figure_custom {
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border: 5px solid transparent;
  margin: 0; }

.figcaption_custom {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: left;
  font-weight: bold;
  width: 100%;
  height: 100%;
  display: table; }
  .figcaption_custom .div_custom {
    position: relative;
    top: 60px;
    left: 0;
    color: #333333;
    text-align: center;
    text-transform: uppercase;
    font-size: 24px;
    background: #fff;
    opacity: 0.9;
    padding: 10px 0; }

.figure_custom .img_custom {
  transition: all 0.5s linear;
  transform: scale3d(1, 1, 1); }

.figure_custom:hover .figcaption_custom {
  background-color: rgba(16, 16, 16, 0.85); }

.figcaption_custom:hover .div_custom {
  opacity: 1;
  color: #ccc; }
  .figcaption_custom:hover .div_custom:after {
    width: 50%; }

.figure_custom:hover .img_custom {
  transform: scale3d(1.2, 1.2, 1); }

@media only screen and (min-width: 320px) {
  .multi-column-dropdown li a {
    padding: 10px 0 10px 20px; } }

@media only screen and (min-width: 360px) {
  .multi-column-dropdown li a {
    padding: 10px 0 10px 20px; } }

@media only screen and (min-width: 480px) {
  .multi-column-dropdown li a {
    padding: 10px 0 10px 20px; } }

@media only screen and (min-width: 768px) {
  .multi-column-dropdown li a {
    padding: 10px 0 10px 20px; } }

@media only screen and (min-width: 992px) {
  .multi-column-dropdown li a {
    padding: 10px 0 10px 20px; } }

@media only screen and (min-width: 1200px) {
  .multi-column-dropdown li a {
    padding: 10px 0 10px 20px; } }

.dropdown-menu.columns-3 {
  min-width: 600px;
  background: #4B4E53;
  border: none;
  border-radius: 0; }

.dropdown-menu li a {
  font-weight: 300; }

.multi-column-dropdown {
  list-style: none;
  margin: 0;
  padding: 0; }

.multi-column-dropdown li a {
  display: block;
  clear: both;
  line-height: 1.428571429;
  color: #d3d3d3;
  white-space: normal;
  text-transform: uppercase; }

.multi-column-dropdown li a:hover {
  text-decoration: none;
  color: #fff;
  background-color: transparent; }

.file-input {
  position: relative;
  float: left;
  margin: 5px 0px 0px 15px;
  padding: 0px;
  height: 34px;
  text-align: left;
  border-width: 1px;
  border-style: solid;
  border-color: #dfdfdf;
  background-color: #ffffff; }

.file-input.error {
  border-color: #E03E64; }

.file-input:before {
  content: "";
  right: 0px;
  top: 0px;
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  position: absolute;
  display: block;
  pointer-events: none;
  box-sizing: border-box;
  border-radius: 0px 4px 4px 0px; }

.file-input:after {
  content: "";
  width: 16px;
  height: 16px;
  background-image: url(/img/search-icon-sprite.png);
  background-repeat: no-repeat;
  background-color: #ffffff;
  position: absolute;
  top: 8px;
  right: 10px;
  box-sizing: border-box;
  pointer-events: none; }

.file-input > input {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px 7px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  text-indent: -9999px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  z-index: 2; }

.file-input > span {
  font-family: "open_sansregular";
  font-size: 14px;
  color: #797979;
  position: absolute;
  top: 9px;
  left: 12px;
  pointer-events: none;
  z-index: 1; }

/* File input colors */
.file-input.green:after {
  background-position: -16px 0px; }

.file-input.cyan:after {
  background-position: -32px 0px; }

.file-input.red:after {
  background-position: 0px -16px; }

.file-input.yellow:after {
  background-position: -16px -16px; }

.file-input.violet:after {
  background-position: -32px -16px; }

.file-input.orange:after {
  background-position: 0px -32px; }

.file-input.olive:after {
  background-position: -16px -32px; }

.file-input.brown:after {
  background-position: -32px -32px; }

.file-error-message {
  margin-top: 37px !important; }

.file-input {
  position: relative;
  float: left;
  margin: 5px 0px 0px 15px;
  padding: 0px;
  height: 34px;
  text-align: left;
  border-width: 1px;
  border-style: solid;
  border-color: #dfdfdf;
  background-color: #ffffff; }

.file-input.error {
  border-color: #E03E64; }

.file-input:before {
  content: "";
  right: 0px;
  top: 0px;
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  position: absolute;
  display: block;
  pointer-events: none;
  box-sizing: border-box;
  border-radius: 0px 4px 4px 0px; }

.file-input:after {
  content: "";
  width: 16px;
  height: 16px;
  background-image: url(/img/search-icon-sprite.png);
  background-repeat: no-repeat;
  background-color: #ffffff;
  position: absolute;
  top: 8px;
  right: 10px;
  box-sizing: border-box;
  pointer-events: none; }

.file-input > input {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px 7px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  text-indent: -9999px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  z-index: 2; }

.file-input > span {
  font-family: "open_sansregular";
  font-size: 14px;
  color: #797979;
  position: absolute;
  top: 9px;
  left: 12px;
  pointer-events: none;
  z-index: 1; }

/* File input colors */
.file-input.green:after {
  background-position: -16px 0px; }

.file-input.cyan:after {
  background-position: -32px 0px; }

.file-input.red:after {
  background-position: 0px -16px; }

.file-input.yellow:after {
  background-position: -16px -16px; }

.file-input.violet:after {
  background-position: -32px -16px; }

.file-input.orange:after {
  background-position: 0px -32px; }

.file-input.olive:after {
  background-position: -16px -32px; }

.file-input.brown:after {
  background-position: -32px -32px; }

.file-error-message {
  margin-top: 37px !important; }

.thumbnail-image-preview {
  max-width: 595px;
  max-height: 595px; }






.custom-button-spacer {
  padding-bottom: 37px !important; }



.custom-paragraph {
  display: inline-block;
  margin: 0px; }

.custom-paragraph > p {
  display: inline-block;
  margin: 0px !important; }





.ForgotPassword-input {
  margin-bottom: 0; }

.showbox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5%;
  min-height: 1000px !important; }

.loader {
  position: relative;
  margin: 0 auto;
  width: 100px; }
  .loader:before {
    content: '';
    display: block;
    padding-top: 100%; }

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; }

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round; }

@keyframes rotate {
  100% {
    transform: rotate(360deg); } }

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px; } }

@keyframes color {
  100%,
  0% {
    stroke: #d62d20; }
  40% {
    stroke: #0057e7; }
  66% {
    stroke: #008744; }
  80%,
  90% {
    stroke: #ffa700; } }

.equal-height {
  height: 300px; }









.logo-footer-cont2 {
  margin-bottom: 30px;
  background-color: transparent;
  padding: 1em;
  border-radius: 5px;
  display: inline-block; }

.nav_custom {
  background-color: #000;
  border: none; }

.navbar-brand {
  padding: 0; }

.navbar-brand > img {
  height: 100%;
  padding: 15px;
  width: auto; }

.example3 .navbar-brand {
  height: 100px; }

.example3 .nav > li > a {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff; }

.example3 .nav > li > a:hover {
  color: #ccc; }

.example3 .navbar-toggle {
  padding: 10px;
  margin: 35px 15px 35px 0;
  background-color: transparent; }

.example3 .navbar-toggle:hover {
  padding: 10px;
  margin: 35px 15px 35px 0;
  background-color: transparent; }

.example3 .navbar-toggle:focus {
  padding: 10px;
  margin: 35px 15px 35px 0;
  background-color: transparent; }

.example3 .navbar-toggle:visited {
  padding: 10px;
  margin: 35px 15px 35px 0;
  background-color: transparent; }

/* CSS Transform Align Navbar Brand Text ... This could also be achieved with table / table-cells */
.navbar-alignit .navbar-header {
  transform-style: preserve-3d;
  height: 50px; }

.navbar-alignit .navbar-brand {
  top: 50%;
  display: block;
  position: relative;
  height: auto;
  transform: translate(0, -50%);
  margin-right: 15px;
  margin-left: 15px; }

.navbar-nav > li > .dropdown-menu {
  z-index: 9999; }

@media only screen and (min-width: 320px) {
  .example3 .nav > li > a {
    padding: 0 0 5px 20px;
    margin: 40px 0 40px 0; } }

@media only screen and (min-width: 360px) {
  .example3 .nav > li > a {
    padding: 0 0 5px 20px;
    margin: 40px 0 40px 0; } }

@media only screen and (min-width: 480px) {
  .example3 .nav > li > a {
    padding: 0 0 5px 20px;
    margin: 40px 0 40px 0; } }

@media only screen and (min-width: 768px) {
  .example3 .nav > li > a {
    padding: 0 0 5px 0;
    margin: 40px 12px 40px 0; } }

@media only screen and (min-width: 992px) {
  .example3 .nav > li > a {
    padding: 0 0 5px 0;
    margin: 40px 20px 40px 0; } }

@media only screen and (min-width: 1200px) {
  .example3 .nav > li > a {
    padding: 0 0 5px 0;
    margin: 40px 20px 40px 0; } }

.no_padding {
  padding: 0; }

.projects_main_custom {
  padding: 0 30px 30px 30px;
  background-color: #eee; }

.projects_box_custom {
  padding: 30px 40px;
  background-color: #fff; }

.ap_custom {
  padding: 0;
  margin: 0; }

.wrapper_custom {
  padding: 0;
  max-width: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0; }

/* Image zoom on hover + Overlay colour */
.parent_custom {
  margin: 5px;
  border: 1px solid transparent;
  overflow: hidden;
  position: relative;
  cursor: pointer; }

.child_custom {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all .5s; }

.a_custom {
  opacity: 0.9;
  font-size: 24px;
  color: #333333;
  font-family: sans-serif;
  text-align: center;
  position: absolute;
  top: 78%;
  left: 0;
  bottom: 0;
  right: 0;
  height: 50px;
  cursor: pointer;
  text-transform: uppercase;
  line-height: 46px;
  background: #fff;
  text-decoration: none; }

.parent_custom:hover .child_custom, .parent_custom:focus .child_custom {
  transform: scale(1.2); }

.parent_custom:hover .child_custom:before, .parent_custom:focus .child_custom:before, .parent_custom:hover .a_custom, .parent_custom:focus .a_custom {
  display: block;
  color: #ccc; }

.child_custom:before {
  content: "";
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(16, 16, 16, 0.85); }

/* Media Queries */
@media screen and (max-width: 960px) {
  .parent_custom {
    width: 100%;
    margin: 20px 0; }
  .wrapper_custom {
    padding: 20px 20px; } }

.btn-github:hover {
  color: #fff; }

.btn-facebook:hover {
  color: #fff; }

.btn-google:hover {
  color: #fff; }









@media only screen and (min-width: 768px) {
  .navbar-right .dropdown-menu {
    right: auto;
    left: 0; } }

@media only screen and (min-width: 992px) {
  .navbar-right .dropdown-menu {
    right: auto;
    left: 0; } }

@media only screen and (min-width: 1200px) {
  .navbar-right .dropdown-menu {
    right: auto;
    left: 0; }
  .dropdown:hover .dropdown-menu {
    display: block; } }

.nav_custom .nav > li.current > a {
  border-bottom: 2px solid yellow;
  color: #ccc; }







.file-input {
  position: relative;
  float: left;
  margin: 5px 0px 0px 15px;
  padding: 0px;
  height: 34px;
  text-align: left;
  border-width: 1px;
  border-style: solid;
  border-color: #dfdfdf;
  background-color: #ffffff; }

.file-input.error {
  border-color: #E03E64; }

.file-input:before {
  content: "";
  right: 0px;
  top: 0px;
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  position: absolute;
  display: block;
  pointer-events: none;
  box-sizing: border-box;
  border-radius: 0px 4px 4px 0px; }

.file-input:after {
  content: "";
  width: 16px;
  height: 16px;
  background-image: url(/img/search-icon-sprite.png);
  background-repeat: no-repeat;
  background-color: #ffffff;
  position: absolute;
  top: 8px;
  right: 10px;
  box-sizing: border-box;
  pointer-events: none; }

.file-input > input {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px 7px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  text-indent: -9999px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  z-index: 2; }

.file-input > span {
  font-family: "open_sansregular";
  font-size: 14px;
  color: #797979;
  position: absolute;
  top: 9px;
  left: 12px;
  pointer-events: none;
  z-index: 1; }

/* File input colors */
.file-input.green:after {
  background-position: -16px 0px; }

.file-input.cyan:after {
  background-position: -32px 0px; }

.file-input.red:after {
  background-position: 0px -16px; }

.file-input.yellow:after {
  background-position: -16px -16px; }

.file-input.violet:after {
  background-position: -32px -16px; }

.file-input.orange:after {
  background-position: 0px -32px; }

.file-input.olive:after {
  background-position: -16px -32px; }

.file-input.brown:after {
  background-position: -32px -32px; }

.file-error-message {
  margin-top: 37px !important; }

.custom-button-size {
  height: 30px;
  width: 30px;
  font-size: 35px !important;
  margin-top: 6px; }

.custom-button-background {
  border: none;
  background: transparent; }

.left-padding-custom {
  padding-left: 10%; }

#mapa {
  margin-top: 15px; }

.file-input {
  position: relative;
  float: left;
  margin: 5px 0px 0px 15px;
  padding: 0px;
  height: 34px;
  text-align: left;
  border-width: 1px;
  border-style: solid;
  border-color: #dfdfdf;
  background-color: #ffffff; }

.file-input.error {
  border-color: #E03E64; }

.file-input:before {
  content: "";
  right: 0px;
  top: 0px;
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  position: absolute;
  display: block;
  pointer-events: none;
  box-sizing: border-box;
  border-radius: 0px 4px 4px 0px; }

.file-input:after {
  content: "";
  width: 16px;
  height: 16px;
  background-image: url(/img/search-icon-sprite.png);
  background-repeat: no-repeat;
  background-color: #ffffff;
  position: absolute;
  top: 8px;
  right: 10px;
  box-sizing: border-box;
  pointer-events: none; }

.file-input > input {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px 7px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  text-indent: -9999px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  z-index: 2; }

.file-input > span {
  font-family: "open_sansregular";
  font-size: 14px;
  color: #797979;
  position: absolute;
  top: 9px;
  left: 12px;
  pointer-events: none;
  z-index: 1; }

/* File input colors */
.file-input.green:after {
  background-position: -16px 0px; }

.file-input.cyan:after {
  background-position: -32px 0px; }

.file-input.red:after {
  background-position: 0px -16px; }

.file-input.yellow:after {
  background-position: -16px -16px; }

.file-input.violet:after {
  background-position: -32px -16px; }

.file-input.orange:after {
  background-position: 0px -32px; }

.file-input.olive:after {
  background-position: -16px -32px; }

.file-input.brown:after {
  background-position: -32px -32px; }

.file-error-message {
  margin-top: 37px !important; }

.thumbnail-image-preview {
  max-width: 540px !important;
  max-height: 540px !important; }

.custom-button-size-fix {
  margin-top: 0px !important; }


.dataTables_empty {
  display: none; }

@media only screen and (min-width: 320px) {
  .magic-nav-left {
    visibility: hidden;
    position: absolute;
    top: 30%;
    left: -6%;
    border-radius: 8px;
    height: 25px;
    width: 25px;
    border: 0;
    z-index: 9999;
    text-align: center;
    color: #666; }
  .magic-nav-right {
    visibility: hidden;
    border: 0;
    position: absolute;
    top: 30%;
    right: -6%;
    border-radius: 8px;
    height: 25px;
    width: 25px;
    z-index: 9999;
    text-align: center;
    color: #666; } }

@media only screen and (min-width: 480px) {
  .magic-nav-left {
    visibility: hidden;
    position: absolute;
    border: 0;
    top: 30%;
    left: -6%;
    border-radius: 8px;
    height: 25px;
    width: 25px;
    z-index: 9999;
    text-align: center;
    color: #666; }
  .magic-nav-right {
    visibility: hidden;
    border: 0;
    position: absolute;
    top: 30%;
    right: -6%;
    border-radius: 8px;
    height: 25px;
    width: 25px;
    z-index: 9999;
    text-align: center;
    color: #666; } }

@media only screen and (min-width: 768px) {
  .magic-nav-left {
    visibility: hidden;
    border: 0;
    position: absolute;
    top: 30%;
    left: -6%;
    border-radius: 8px;
    height: 35px;
    width: 35px;
    z-index: 9999;
    text-align: center;
    color: #666; }
  .magic-nav-right {
    visibility: hidden;
    border: 0;
    position: absolute;
    top: 30%;
    right: -33px;
    height: 35px;
    width: 35px;
    z-index: 9999;
    text-align: center;
    color: #666; } }

@media only screen and (min-width: 992px) {
  .magic-nav-left {
    visibility: hidden;
    position: absolute;
    border: 0;
    top: 30%;
    left: -6%;
    border-radius: 8px;
    height: 35px;
    width: 35px;
    z-index: 9999;
    text-align: center;
    color: #666; }
  .magic-nav-right {
    visibility: hidden;
    position: absolute;
    border: 0;
    top: 30%;
    right: -6%;
    border-radius: 8px;
    height: 35px;
    width: 35px;
    z-index: 9999;
    text-align: center;
    color: #666; } }

@media only screen and (min-width: 1200px) {
  .magic-nav-left {
    visibility: visible;
    position: absolute;
    border: 0;
    top: 30%;
    left: -6%;
    border-radius: 8px;
    height: 45px;
    width: 45px;
    z-index: 9999;
    text-align: center;
    transition: all 0.3s ease-out 0s;
    color: #666; }
  .favicon-left {
    position: absolute;
    top: 8px;
    left: 17px;
    color: #666;
    font-size: 30px !important; }
  .favicon-right {
    position: absolute;
    top: 8px;
    left: 17px;
    color: #666;
    font-size: 30px !important; }
  .magic-nav-left:hover {
    border: 0;
    color: #eee; }
  .magic-nav-right {
    visibility: visible;
    position: absolute;
    border: 0;
    top: 30%;
    right: -6%;
    border-radius: 8px;
    height: 45px;
    width: 45px;
    z-index: 9999;
    text-align: center;
    transition: all 0.3s ease-out 0s;
    color: #666; }
  .magic-nav-right:hover {
    border: 0;
    color: #eee; } }

.divider_custom {
  padding-top: 40px;
  border-bottom: 1px solid #000000; }

.RegisterForm-inputContainer {
  margin-top: 10px;
  margin-bottom: 0px;
  width: 80%;
  min-width: 300px; }

.RegisterForm-submit {
  margin-bottom: 40px;
  margin-left: 0;
  width: 100%;
  padding: 5px 0; }

.ResetPassword-input {
  margin-bottom: 0; }

@media only screen and (max-width: 420px) {
  .equal-height2 {
    height: 654px; } }

@media only screen and (min-width: 320px) {
  .equal-height2 {
    height: 654px; } }

/*==================================================
=       Extra Small Devices, Portrait Phones       =
==================================================*/
@media only screen and (min-width: 480px) {
  .equal-height2 {
    height: 654px; } }

/*=================================================================================
= Small Devices, Landscape Phones and Phablets, Portrait Tablets, Small Desktops =
=================================================================================*/
@media only screen and (min-width: 768px) {
  .equal-height2 {
    height: 654px; } }

/*======================================================
= Medium Devices, Landscape Tablets, Laptops, Desktops =
======================================================*/
@media only screen and (min-width: 992px) {
  .equal-height2 {
    height: 654px; } }

/*==================================================
=   Large Devices, Large Desktops, Wide Screens    =
==================================================*/
@media only screen and (min-width: 1200px) {
  .equal-height2 {
    height: 654px; } }

.fes3-main-text-cont {
  padding: 145px 50px 140px 50px !important; }














.Footer {
  text-align: center;
  background-color: #F6F9FA;
  border-top: 1px solid #E9F0F3;
  padding-top: 40px;
  padding-bottom: 40px;
  color: #888888;
  font-weight: 300; }

.Footer-text {
  padding-top: 10px;
  opacity: 0.8;
  color: #888888; }

.Footer-link {
  text-decoration: none;
  color: #888888 !important;
  font-weight: 400; }

.Footer-link:hover {
  text-decoration: underline; }

.Footer-logo {
  width: 49px;
  height: 46px; }

.ForgotPassword-formContainer {
  margin-top: 80px;
  margin-bottom: 80px; }


.Landing {
  height: 100%; }

.Landing-cover {
  background-image: url("/img/cover.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  width: 100%;
  height: 700px; }

.Landing-heading,
.Landing-subtitle {
  color: white; }

.Landing-headingLight {
  font-weight: 300; }

.Landing-heading {
  color: white;
  letter-spacing: -3px;
  margin-top: 20px;
  font-size: 60px;
  text-align: center; }

.Landing-subtitle {
  opacity: 0.7;
  text-align: center; }

.Landing-getStarted {
  padding: 12px 30px;
  font-weight: bold;
  color: #535362 !important;
  border-radius: 4px;
  text-transform: box-shadow 250ms; }

.Landing-getStarted:hover {
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12) !important; }

.Landing-laravelAngular {
  position: relative; }

.Landing-ampersand {
  font-size: 50px;
  color: #00A4C6;
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 300; }

.Landing-angular {
  background-color: #F6F9FA;
  border-left: 1px solid #E9F0F3; }

.Landing-laravel,
.Landing-angular {
  text-align: center;
  color: #21455B;
  border-bottom: 1px solid #E9F0F3; }

.Landing-laravelAngular-title {
  padding-top: 30px;
  margin-bottom: 15px;
  font-weight: 300;
  letter-spacing: -1px;
  color: #21455B; }

.Landing-laravelAngular-subtitle {
  color: #535362;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 300; }

.DemoCode {
  font-family: "Lucida Console", Monaco, monospace;
  overflow: auto !important;
  padding: 10px;
  line-height: 1.428571429;
  color: #333333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #cccccc;
  border-radius: 4px;
  width: 80%;
  margin: 30px auto 100px;
  text-align: left;
  padding-left: 20px;
  font-size: 14px; }

.DemoCode-highlight {
  color: #DD4A68; }

.DemoCode-comment {
  color: #888888; }

.DemoCode-operator {
  color: #888888; }

.DemoCode-string {
  color: #690; }

.DemoCode-secondary {
  color: #07A; }

.Landing-features {
  text-align: center;
  margin: 60px 10px; }

.Landing-featuresMainTitle {
  font-weight: 300;
  letter-spacing: -1px;
  margin-bottom: 15px;
  color: #21455B; }

.Landing-featuresMainDescription {
  width: 70%;
  margin: 0 auto 30px;
  color: #535362;
  font-weight: 300; }

.Landing-featuresEntry {
  width: 176px;
  height: 176px;
  border-radius: 50%;
  margin: 40px auto 20px; }

.Landing-featuresEntry-icon {
  height: 100%; }

.Landing-featuresTitle {
  color: #21455B;
  letter-spacing: -1px;
  margin-top: 40px; }

.Landing-featuresDescription {
  padding-top: 10px;
  padding-left: 50px;
  padding-right: 50px;
  font-weight: 300;
  color: #535362; }

.Landing-featuresEntry--restful {
  background-color: #00A4C6; }

.Landing-featuresEntry--jwt {
  background-color: #EF704D; }

.Landing-featuresEntry--generators {
  background-color: #25C0CC; }

.Landing-featuresEntry--restful md-icon {
  width: 100px; }

.Landing-featuresEntry--jwt md-icon {
  width: 80px; }

.Landing-featuresEntry--generators md-icon {
  width: 80px; }

/*
This is a terrible temporary hack,
to fix layout issues with flex on iOS (chrome & safari)
Make sure to remove this when you modify this demo
*/
@media only screen and (max-width: 950px) {
  .iOS-hack .flex-100,
  .iOS-hack .flex-50,
  .iOS-hack .flex-33 {
    -ms-flex: none !important;
    flex: none !important; } }

.dataTables_filter {
  float: right; }

.dataTables_filter input {
  margin: 0px 5px 0px 5px; }

.dataTables_paginate {
  float: right; }

.dataTables_paginate .pagination {
  margin: 0px; }

.list_custom {
  display: inline-block;
  text-align: left; }


.Login-formContainer {
  margin-top: 80px;
  margin-bottom: 80px; }


.Register-formContainer {
  margin-top: 80px;
  margin-bottom: 80px; }

.ResetPassword-formContainer {
  margin-top: 80px;
  margin-bottom: 80px; }

.Login-formContainer {
  margin-top: 80px;
  margin-bottom: 80px; }

/*# sourceMappingURL=app.css.map */
