/* THEME FIXINGS */
.btn-4aa-inv .button-icon-anim {
	left: 87%;
	top: 49%;
}
.button,
#main-menu .navbar .nav > li > a .main-menu-title {
	text-transform: uppercase;
}
input[type="checkbox"] {
	margin-right: 0.5em;
	position: relative;
	top: 2px;
}
label {
	cursor: pointer;
}
.icon-list {
	margin-left: 9px;
}
.icon-list i {
	margin-right: 6px;
	top: 1px;
	position: relative;
	vertical-align: middle;
	font-size: 1.5em;
}
.logo-footer-cont {
	margin-bottom: 30px;
	background-color: #000;
	padding: 1em;
	border-radius: 5px;
	display: inline-block;
}
.footer-2-soc-a {
	margin-bottom: 20px;
}
.footer-2-soc-a a:first-child {
	padding-left: 0;
}
.it-designer-logo {
	float:right;
}
.it-designer-logo img {
	max-width: 150px;
}
.logo-img {
	height: 80px;
}
.logo-row {
	width: auto;
	display: inline-block;
}
.header.no-transparent .logo-2 {
	background-color: #000;
	padding-left:2em;
	padding-right:3em; 
}
.pt-150 {
	padding-top: 150px;
}
.port-view-more,
.port-view-more-dark {
	text-transform: uppercase;
}
/* CONTACT PAGE */
.cis-text span.icon {
	margin-right: 0.5em;
	position: relative;
	top: 1px;
}
.cis-cont {
	padding-left: 0;
}
.cis-icon {
	display: none;
}

/* FRONT PAGE */
.welcome-cont h1 {
	margin-top: 0px;
}
.services .title-fs-45 {
	text-transform: uppercase;
}
.port-view-more-cont {
	margin-bottom: 15px;
}
.contact-call-action {
	margin: 0 0 !important;
}
.blog-container {
	padding: 80px 0 50px;
}

/* SERVICE FORM */
.service-form-cont .inputs-cont {
	margin: 0 auto;
	max-width: 700px;
}
.service-form-cont [class^="col-"]:first-child .inputs-cont {
	padding: 145px 0 140px 150px;
}
.service-form-cont [class^="col-"]:last-child .inputs-cont {
	padding: 145px 150px 140px 0;
}

#contact-form h2 {
	margin-bottom: 2em;
}

#contact-form h3 {
	margin-bottom: 2.35em;
}
/* SINGLE PRODUCT */
.port-detail p strong {
	width: auto;
	display: inline;
}
.port-detail .icon-list {
	margin-top: 2em;
	margin-bottom: 2em;
}
.port-detail {
	font-size: 14px;
}
.port-detail .button {
	margin-top: 2em;
}
.service-form-cont.single-prod-form [class^="col-"]:first-child .inputs-cont {
	padding: 115px 0 100px 150px;
}
.service-form-cont.single-prod-form [class^="col-"]:last-child .inputs-cont {
	padding: 115px 150px 100px 0;
}

h1,h2,h3 {
	text-transform: uppercase;
}
.inline-block {
	display: inline-block;
	margin-right: -4px;
	vertical-align: middle;
}
/* FOOTER */
footer .widget h4 {
	margin-top: 0;
}
.menu-list a {
	text-transform: uppercase;
}
.footer-2-copy-cont {
	background-color: #fafafa;
	padding-bottom: 30px;
}
footer .inline-block {
	width: 50%;
}
/* INSTALLATION */
.installation-gallery {
  will-change: background-position;
  margin: 0;
  padding: 0;
  background:#2c2c2c url(../../images/work-proc-bg.jpg) fixed;
}
.installation-gallery .owl-item {
	padding: 0 0.5em;
}
.services-img.fes2-img {
	background: url(../../images/projector-repair.jpg) 50% 50% no-repeat;
}
.agreement-image.fes2-img {
	background: url(../../images/agreement.jpg) 50% 50% no-repeat;
}
.count-title {
	text-transform: uppercase;
}
.norm-30-wide { 
	width:100%;
	font-size:30px;
	line-height:45px;
	font-weight:normal;
	letter-spacing:7px;
	text-transform: uppercase;
}
.font-white {
  color:#ededed !important;
}
#main-menu .navbar .navbar-collapse {
	margin-top: 6px;
}

/* SLIDER */
/*.slides li {
	height: 800px;
}
*/
.firstslide-title .dark-bg {
	display: inline-block;
	background-color: rgba(0,0,0,0.8);
	color: #fff;
	padding: 0.7em;
	border:4px solid #fff;
}
.second-slide .slider-caption-border {
	background-color: rgba(0,0,0,0.5);
}
.cis-text span.fa {
	margin-right: 0.5em;
}
.arrow_carrot-2right:before {
	position: relative;
	top: 1px;
	left: 0;
	transition:left 0.2s linear;
}
a.button:hover .arrow_carrot-2right:before {
	left: 5px;
}
.blog-single-fullwidth-img {
	padding-top: 300px;
}
.form-submit-btn {
	margin-left: 60px;
}
.blog2-post-prev-text {
	height: 110px;
}
/* Mobile style fixings
================================================== */
@media (max-width: 768px) {
	.welcome-cont h1 {
		font-size: 22px;
		line-height: 32px;
	}
	/*.slides li {
		height: auto;
	}*/
	.example3 .navbar-brand {
		height: 80px;
	}
	.example3 .navbar-toggle,
	.example3 .navbar-toggle:hover {
		margin: 25px 15px 25px 0;
	}
	.service-form-cont.single-prod-form [class^="col-"]:first-child .inputs-cont,
	.service-form-cont.single-prod-form [class^="col-"]:last-child .inputs-cont,
	.service-form-cont [class^="col-"]:first-child .inputs-cont,
	.service-form-cont [class^="col-"]:last-child .inputs-cont {
		padding: 0;
	}
	.page-title-cont.page-title-large {
		padding-top: 90px;
	}
	.form-submit-btn {
		margin-left: 0;
	}
	.widget.hide-mobile {
		display: none;
	}
	.fes2-main-text-cont {
		padding: 70px 30px 70px 30px !important;
	}
	.services-img.fes2-img {
		height: 700px;
	}
}
@media (max-width: 600px) {
	.services-img.fes2-img {
		height: 600px;
	}
}
@media (max-width: 500px) {
	.services-img.fes2-img {
		height: 500px;
	}
}
@media (max-width: 400px) {
	.services-img.fes2-img {
		height: 400px;
	}
}
@media (max-width: 300px) {
	.services-img.fes2-img {
		height: 300px;
	}
}